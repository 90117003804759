.bodyPage {
  /*max-width: 700px;
  margin: 150px auto 0;
  max-width: 450px;
  width: 100%;
  display: flex;*/
  /*flex-direction: column;
  align-items: center;
  margin-top: 10%;*/
}
.bodyPage h1#logo {
  font-size: 90px;
  font-weight: bold;
  color: var(--colorDefault);
  margin-bottom: 15px;
}
.bodyPage .content {
  /*width: 100%;*/
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
  border: 0;
  box-shadow: 0px 0px 0 0px rgb(177, 177, 177);
  /*text-align: center;*/
  margin: 15px;
}
.bodyPage .content > p {
  color: rgb(153, 153, 153);
}
.bodyPage .content > h1 {
  color: rgb(109, 109, 109);
  text-transform: uppercase;
  font-size: 30px
}
.bodyPage .content {
  max-width: 600px;
  width: 100%;
}
.bodyPage .examplesIris .itemExemploIris img {
  width: 100%;
  border-radius: 12px;
}
.bodyPage .examplesIris .itemExemploIris p {
  font-size: 14px;
  margin-top: 8px;
  line-height: initial;
}
@media only screen and (max-width: 552px) {
  .bodyPage h1#logo {
    font-size: 80px;
  }
}