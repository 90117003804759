.booking {
  /*margin: 150px auto 0;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;*/
}
.booking .header p{
  font-size: 16px;
}
.booking .header #imgBusiness {
  width: 160px;
  height: 160px;
  margin-bottom: 10px;
  margin-top: 30px;
  display: inline-flex;
  border: 3px solid var(--colorDefault);
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px -1px #b7b7b7;
  --webkit-box-shadow: 0px 0px 10px -1px #b7b7b7;
}
.booking .content {
  /*width: 100%;*/
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 12px;
  padding: 25px 15px;
  border: 2px solid rgb(245, 245, 245);
  box-shadow: 0px 0px 10px -2px #b7b7b7;
  --webkit-box-shadow: 0px 0px 10px -2px #b7b7b7;
  /*text-align: center;*/
}
.booking .content > h1 {
  color: rgb(109, 109, 109);
  text-transform: uppercase;
  font-size: 30px;
}
.booking .content {
  /*max-width: 800px;
  width: 100%;*/
}
.booking .content p {
  font-weight: 600;
  margin-bottom: 8px;
}
.booking .divButtonSelect {
  display: inline-flex;
  width: 100%;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.booking .divButtonSelect .itemButtonSelect {
  width: 50%;
  padding: 10px 15px;
  cursor: pointer;
}
.booking .divButtonSelect .itemButtonSelect.active {
  background: var(--colorDefault);
  color: #fff;
}
.divBtnNotification {
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  margin: 15px;
  background: #09c4a9;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  box-shadow: 0px 0px 10px -1px #b7b7b7;
  --webkit-box-shadow: 0px 0px 10px -1px #b7b7b7;
  z-index: 98;
  cursor: pointer;
}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 552px) {
  .booking #imgBusiness {
    width: 110px;
    height: 110px;
  }
  .content {
    padding: 8px;
  }
}