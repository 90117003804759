.barraSuperior, .barraSuperior.affix {
  width: 100%;
  background: var(--colorDefaultHover);
  color: var(--backgroundDefault);
  z-index: 999;
  padding: 8px 15px;
  box-shadow: 0px 5px 5px -2px #919191;
  --webkit-box-shadow: 0px 5px 5px -2px #919191;
  position: fixed;
}
.barraSuperior .logo h1{
  font-size: 40px;
  font-weight: bold;
  color: var(--thirdColor);
  margin: 0px;
  margin-top: -5px;
}
.barraSuperior .imgMenu #btnMenu {
  cursor: pointer;
  color: var(--backgroundDefault);
}
#menuUser {
  background: var(--colorDefaultHover);
  color: var(--backgroundDefault);
  position: fixed;
  right: 0;
  top: 59px;
  height: 100%;
  z-index: 9999;
  width: auto;
  padding: 0px;
  visibility: hidden;
  transform: translateX(250px);
  transition: all 0.3s ease;
  box-shadow: 0px 10px 10px -2px var(--colorText);
  --webkit-box-shadow: 0px 10px 10px -2px var(--colorText);
}
#menuUser.active {
  visibility: visible;
  transform: translateX(0px);
}
#menuUser li{
  padding: 15px 40px 15px 25px;
  list-style: none;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}
#menuUser li.active, #menuUser li:hover{
  background: var(--colorDefault);
  color: white;
}
#menuUser li span,  #menuUser li svg{
  margin-right: 15px;
}
#menuUser li img {
  margin-top: -5px;
}
#titleMenu {
  text-transform: uppercase;
  font-family: 'Font-Bold';
  font-size: 18px;
  color: #fff;
}
.bodyDashboard {
  margin: 0px auto 0;
  /*padding-top: 15px;
  max-width: 900px;
  max-height: 92vh;*/
  min-height: 90vh;
  height: 100%;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
.itensHome {
  width: 100%;
  /*height: 80vh;*/
  padding-bottom: 60px;
}
.itensHome #imgUser {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--colorDefault);
  background-color: var(--colorDefaultHover);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.itemHome #imgUser{
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  margin-top: 20px;
  display: inline-flex;
  border: 3px solid var(--colorDefault);
  background-color: var(--colorDefaultHover);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.itensHome .title{
  margin-top: 30px;
  margin-left: 15px;
}
.itemHome {
  padding: 20px 15px;
  margin: 5px;
  box-shadow: 0px 0px 8px 0px rgb(210 210 210);
  --webkit-box-shadow: 0px 0px 8px 0px rgb(210 210 210);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  background: #fff;
}
.itemHome span{
  color: var(--colorText2);
}
.itemHome .itemHomeIcon{
  color: var(--colorDefault);
}
.itemHome .title{
  margin-top: 20px;
  color: var(--colorDefault);
  font-weight: bold;
  font-size: 25px;
  margin-left: 0;
}
.itemHome #imgBusiness {
  width: 100%;
  height: 170px;
  background-size: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin: 0px;
  background-size: cover;
  background-position: center;
}
.dashboardList .itemDashboardList, .musicList .itemMusicList {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px;
  background: rgb(253, 253, 253);
  /*border: 2px solid rgb(245, 245, 245);*/
  box-shadow: 0px 2px 8px -2px rgb(177, 177, 177);
  --webkit-box-shadow: 0px 2px 8px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
  font-family: 'Font-Bold';
  font-size: 18px;
  color: var(--colorDefaultHover);
}
.userList .itemUserList {
  margin-bottom: 0px;
  margin-top: 8px;
  border-radius: 5px;
  padding: 10px 10px;
  background: rgb(253, 253, 253);
  box-shadow: 0px 2px 5px -2px rgb(177, 177, 177);
  --webkit-box-shadow: 0px 2px 5px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.userList label#thumbnail{
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--colorDefault);
  background-color: var(--colorDefaultHover);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.userList form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 2px;
}
.dashboardList .itemDashboardListActive{
  box-shadow: 0px 0px 10px 1px #636363;
  --webkit-box-shadow: 0px 0px 10px 1px #636363;
  /*paddingbox-shadow: 0px 0px 2px 3px var(--colorDefault);
  border: 0;*/
}
.headList {
  cursor: pointer;
}
.dashboardList .itemDashboardList .iconItem, .musicList .itemMusicList .iconItem{
  margin-top: 0px;
  color: dimgrey;
  font-size: 18px;
}
.dashboardList .itemDashboardList .edit{
  color: #01d401;
}
.dashboardList .itemDashboardList .delete{
  color: #888888;
}
.dashboardList .itemDashboardList .itemImg {
  width: 100%;
  max-width: 160px;
}
.dashboardList .itemDashboardList label#thumbnail, label#thumbnailBusiness{
  display: flex;
  width:100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList label#background{
  width:100%;
  height: 350px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList .itemDashboardListSwitch {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: -10px;
  z-index: 10;
}
.dashboardEditbusiness {
  margin-bottom: 15px;
  margin-top: 20px;
  list-style: none;
  padding: 15px 0px;
}
.linkListItem {
  text-decoration: underline;
  font-weight: bold;
  margin-left: 20px;
  margin-top: -15px;
}
/*.musicList .itemMusicList label#thumbnail {
  width: 100%;
  height: 250px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}*/
label#thumbnail input, label#background input, label#imageShare input, label#thumbnailBusiness input {
  display: none;
}
label#thumbnail.has-thumbnail, label#background.has-thumbnail, label#imageShare.has-thumbnail, label#thumbnailBusiness.has-thumbnail {
  border: 0 !important;
}
label#thumbnail img, label#background img, label#imageShare img {
  /*top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -100%);*/
}
label#thumbnail.has-thumbnail img, label#background.has-thumbnail img, label#imageShare.has-thumbnail img, label#thumbnailBusiness.has-thumbnail img{
  display: none;
}
.title {
  color: var(--colorText);
  font-weight: bold;
  font-family: 'Font-Regular';
  font-size: 24px;
  width: 100%;;
}
.labelFilter {
  color: var(--colorText);
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
}
.iconFilter {
  color: var(--colorText);
}
.btnNew {
  /*margin: 5px 0px;
  padding: 5px;*/
  border-radius: 50%;
  border: none;
  padding-top: 3px;
  width: 50px;
  height: 50px;
  margin: 10px 0px;
  background: var(--colorDefault);
  color: var(--thirdColor);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
  --webkit-box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.btnDeleteImg {
  position: absolute;
  margin-top: 92px;
  margin-left: 27px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 30px;
  height: 30px;
  background: #f8f8f8;
  color: var(--colorDefault);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
  --webkit-box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.iconInputRight{
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 25px;
  margin-top: 13px;
  color: #ababab;
}
.contentEditor {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.contentEditor .inputEditor {
  padding: 15px;
}
.tox-statusbar__branding{
  display: none;
}
.imagesPreview {
  display: inline-block;
  width: 130px;
  height: 130px;
  margin: 10px;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center !important;
  cursor: pointer;
}
#labelImages {
  cursor: pointer;
}
#formNotification{
  width: 100%;
}
.emotionSelected {
  margin-bottom: 5px;
  margin-left: 7px;
  padding: 4px;
  background-color: #ddd;
  border-radius: 5px;
  /*width: 80px;*/
}
.emotionSelected .btnDeleteEmotion {
  margin-left: 8px;
  cursor: pointer;
}
.itemSelected {
  margin-top: -5px;
  margin-bottom: 12px;
  margin-left: 15px;
  padding: 8px 30px 8px 20px;
  background-color: #ddd;
  border-radius: 5px;
  position: relative;
  font-weight: 600;
  /*border: 2px solid var(--colorDefault);
  padding: 10px 25px 10px 20px;
  font-size: 14px;*/
}
.itemSelected .btnDeleteItemSelected {
  margin-left: 12px;
  cursor: pointer;
  color: var(--colorText);
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
}
.itemFinance {
  padding: 12px;
  /*margin: 0px -8px;*/
  border-radius: 6px;
  margin-bottom: 12px;
  padding-bottom: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px -1px rgb(176 176 176);
  --webkit-box-shadow: 0px 0px 10px -1px rgb(176 176 176);
  border-left: 6px solid var(--colorDefault);
}
.itemFinance .financeLabel {
  /*color: #707070;*/
}
.itemFinance .financeValue {
  font-size: 28px;
  font-family: 'Font-Bold';
  color: #3f3f3f;
}
.itemFinance.disponivel{
  border-left: 6px solid var(--secondColor);
} 
.itemFinance.liberar{
  border-left: 6px solid var(--colorDefault);
}
.itemFinance.emAberto{
  border-left: 6px solid var(--thirdColor);
}
.itemFinance.bloqueado{
  border-left: 6px solid red;
}
.itemFinance .btn-transfer {
  background-color: transparent;
  border: 1px solid var(--secondColor);
  color: var(--secondColor);
  padding: 6px 15px;
  border-radius: 6px;
  padding-top: 8px;
  font-size: 16px;
}
.listTransactions {
  margin-top: 15px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.itemLegenda {
  font-size: 12px;
  display: inline;
  margin-right: 8px;
}
.iconLegenda {
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 0px;
  border-radius: 2px;
}
/*Calls */
.titleDate {
  height: "auto"; 
  border-radius: 30px; 
  padding: 15px;
  font-size: 18px;
  color: var(--colorText);

  /*width: auto;
  text-align: center;
  cursor: pointer;
  background: var(--backgroundDefault);
  border: 0;
  margin-top: 5px;*/
}
.iconDate {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 35px;
  margin-top: 21px;
  color: var(--colorText);
}
.iconRefresh {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 35px;
  margin-top: 21px;
  cursor: pointer;
  color: var(--colorDefault);
}
.iconSearchInput {
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px 35px;
  color: #c1c1c1
}
.react-datepicker__navigation {
  margin-top: 12px;
}

/* EVALUATE IRIS */
.irisView {
  background: #000;
  width: 100%;
  height: 500px;
  border-radius: 10px;
}
.imgIrisView {
  /*background-image: url(https://brascrs.com.br/src/uploads/2021/05/iris.png);
  max-width: 2000px;*/
  width: 100%;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}
.labelIris {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  margin: 5px;
  margin-left: 20px;
  background: rgb(0 0 0 / 62%);
  color: #fff;
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  padding: 7px;
}
.react-transform-wrapper, .react-transform-component {
  width: 100% !important;
  height: auto !important;
}
.transform-component-module_content__2jYgh {
  width: auto !important;
  /*height: 485px !important;*/
  border-radius: 10px;
}
.irisViewControls {
  position: relative;
  width: 100%;
  margin-top: -15px;
  background: #000000;
  z-index: 9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px 15px;
  /*text-align: right;*/
}
.irisViewControls span {
  margin: 0px 15px;
}
.irisViewControls i, .irisViewControls svg{
  color: #fff;
  cursor: pointer;
  font-size: 1.2em;
}
.irisViewControls i:hover, .irisViewControls i.active, .irisViewControls svg:hover, .irisViewControls svg.active{
  color: var(--colorDefault);
}
.listViewIris {
  display: flex;
  flex-wrap: wrap;
}
.divImgListViewIris {
  width: 140px;
  height: 100px;
  margin-right: 8px;
  margin-bottom: 15px;
  display: initial;
  position: relative;
}
.imgListViewIris {
  width: 140px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin-right: 8px;
  border: 4px solid #ffffff;
  cursor: pointer;
}
.imgListViewIris:hover, .imgListViewIris.active{
  border: 4px solid var(--colorDefault) !important;
}
.addImgViewIris{
  border: 2px dashed #d4d4d4 !important;
}
.addImgViewIris input{
  display: none;
}
.addImgViewIris span{
  display: table;
  text-align: center;
  width: 100%;
  margin-top: 25px;
}
.addImgViewIris p{
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
  color: #999999;
}
.deleteViewIris {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  margin: 5px 8px;
  cursor: pointer;
  max-height: 25px;
  color: #4f4f4f;
}
.deleteViewIris:hover{
  color: var(--colorDefault) !important;
}
.divImgMapIrisView {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--colorDefault);
}
.imgMapIrisView {
  width: 100%;
  height: 100%;
  /*max-height: 450px;*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  cursor: move;
}
.btnInvertMapColor {
  position: absolute;
  right: 0;
  margin: 12px;
  z-index: 9;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  padding: 7px;
  text-align: center;
  width: 35px;
  height: 35px;
  background: var(--colorDefault);
  color: var(--thirdColor);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
  --webkit-box-shadow: 0px 2px 10px -2px #bfbfbf;
}

/* LUPA */
.magnifier {
	position: relative;
	display: inline-block;
	line-height: 0;
}
.magnifier-image {
	cursor: none;
}
.magnifying-glass {
	position: absolute;
	z-index: 1;
	background: #e5e5e5 no-repeat;
	border: solid #ebebeb;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
	opacity: 0;
	transition: opacity 0.3s;
	pointer-events: none;
}
.magnifying-glass.circle {
  border-radius: 50%;
}
.magnifying-glass.visible {
		opacity: 1; 
}

/*FULL SCREEN */
.fullscreen {
  display: none;
}
.fullscreen.fullscreen-enabled {
  display: initial;
}
.fullscreen .imgIrisView {
  height: 100vh;
}
.btnExitFullScreen {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  background: rgb(0 0 0 / 62%);
}
/* TEMPLATES */
.itemTemplate {
  padding: 8px 5px;
  box-shadow: 0px 0px 8px 0px rgb(210 210 210);
  --webkit-box-shadow: 0px 0px 8px 0px rgb(210 210 210);
  border-radius: 10px;
  cursor: pointer;
  background: #fff;
  border: 4px solid #ffffff;
}
.itemTemplate:hover, .itemTemplate.active {
  border: 4px solid var(--colorDefault);
}
.deleteItemTemplate {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  margin: 15px;
  cursor: pointer;
  max-height: 25px;
  color: #4f4f4f;
}
.deleteItemTemplate:hover {
  color: red;
}
.editItemTemplate {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  margin: 15px;
  cursor: pointer;
  max-height: 25px;
  color: #4f4f4f;
}
.editItemTemplate:hover {
  color: var(--colorDefault);
}

.tableOpeningHours{
  width: 100%;
}
.tableOpeningHours th{
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
  background: #fff;
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .barraSuperior .logo h1{
    font-size: 35px;
  }
  #menuUser {
    top: 54px;
  }
  .dashboardList .itemDashboardList label#thumbnail, label#thumbnailBusiness {
    width:100%;
    height: 160px;
  }
  .itensHome {
    height: auto;
  }
  .itemHome #imgBusiness {
    width: 100%;
    height: 200px;
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .title {
    font-size: 22px;
  }
  #titleMenu {
    font-size: 12px;
  }
}