/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6 {
  color: var(--colorText);
  font-family: 'Font-Regular';
  font-weight: bold;
}
#menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(0, 0, 0, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 99;
}
#menu li {
  margin-bottom: 12px;
}
#menu li a {
  font-size: 28px;
  color: white;
  /*font-family: "Font-Bold";*/
}
.btnMenu {
  padding: 8px 12px;
  z-index: 990;
  position: inherit;
}
.btnMenu i{
  margin-top: 5px;
  font-size: 1.5em;
}
#homeIndex {
  /*background-image: url('../../assets/background.jpg');
  background: var(--colorDefault);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 30px;*/
}

#homeIndex .infoTop {
  display: block;
  background: rgb(21, 21, 21);
  font-family: "Font-Regular" !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
  color: var(--colorDefault) !important;
  margin-top: -12px;
}

#homeIndex #navbar.onScroll .infoTop {
  margin-top: -5px;
}

#homeIndex #navbar{
  padding: 12px 0;
}

#homeIndex #navbar.onScroll{
  padding: 5px 0;
  position: fixed;
  width: 100%;
  background: #f8f8f8;
  z-index: 99;
  box-shadow: 0px 0px 5px -1px grey;
}

#homeIndex .logo img{
  max-width: 120px;
}

#homeIndex .logo h1{
  font-size: 65px;
  font-weight: bold;
  color: var(--colorDefault);
  margin-bottom: 0;
  cursor: pointer;
}

#homeIndex #navbar.onScroll .logo img{
  max-width: 80px;
}

#homeIndex #navbar.onScroll .logo h1{
  font-size: 50px;
}

/*#homeIndex a {
  color: var(--colorDefault);
  text-transform: uppercase;
  line-height: normal;
  font-weight: bold;
}*/

#homeIndex .bodyIndex {
  padding: 60px 0;
  min-height: 77vh;
  place-content: stretch;
  background: var(--backgroundDefault);
  /*border-top-right-radius: 50px;
  border-top-left-radius: 50px;*/
}

#homeIndex #navbar a {
  font-family: 'Font-Bold';
  text-transform: none;
  font-size: 20px;
  padding: 12px 20px;
  color: var(--colorText);
}

#homeIndex #navbar a:hover {
  color: var(--colorDefault);
}

#homeIndex #navbar a.btn-default{
  color: #fff;
}

#homeIndex .bodyIndex p {
  margin-top: 15px;
  font-size: 20px;
}

#homeIndex .bodyIndex .card p{
  color: #004db9;
  margin-bottom: 0;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
}

#homeIndex .bodyIndex button {
  padding: 12px 35px;
}
#homeIndex p {
  font-size: 16px;
}
#homeIndex .footerIndex {
  padding: 15px 0;
  background: #f8f8f8;
}
#homeIndex .footerIndex p, #homeIndex .footerIndex a {
  color: #353535;
  text-transform: none;
  margin-bottom: 8px;
}
#homeIndex #divVideo {
  border-radius: 15px;
  box-shadow: 0px 25px 25px -25px #4e4e4e;
  /*border: 2px solid #969696;*/
}
.divider {
  background-color: var(--colorDefault);
  color: #fff;
  padding: 20px 0px;
}
.divider p, .divider h3 {
  color: #fff !important;
  margin-bottom: 0px;
}
#bodyHowWorks, #bodyKnow, #bodyGalery, #bodyPlans, #bodyWarranty {
  padding: 80px 0;
  /*margin-top: -100px;*/
}
#bodyHowWorks, #bodyWarranty, .divider {
  background-color: var(--colorDefault);
  color: #fff;
}
#bodyHowWorks h1, #bodyWarranty h1, #bodyWarranty .title, #bodyWarranty p {
  color: #fff;
}
#bodyHowWorks .title, #bodyKnow .title, #bodyPlans .title {
 margin-top: 15px;
 font-size: 20px;
}
#bodyHowWorks img {
  padding: 30px;
}
#bodyHowWorks .itemHowWorks {
  padding: 20px 15px;
  border-radius: 8px;
  background: white;
  color: var(--colorText);
  margin-bottom: 15px;
}
#bodyHowWorks .itemHowWorks:hover {
  box-shadow: 0px 0px 10px 1px #dbdbdb;
}
#bodyHowWorks .itemHowWorks .iconItemHowWorks {
  color: var(--colorDefault);
  padding: 15px 10px;
}
#bodyKnow .itemKnow {
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  padding: 8px 12px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
}
#bodyKnow .itemKnow .title{
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
}
#bodyKnow .itemKnow span{
  font-size: 14px;
}
#bodyKnow .itemKnow i{
  color: var(--colorDefault);
}
#bodyPlans .itemPlan {
  box-shadow: 0px 0px 10px 1px #dbdbdb;
  padding: 25px 15px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  border: 2px solid #fff;
}
#bodyPlans .itemPlan .title {
  font-size: 28px;
}
#bodyPlans .itemPlan:hover, #bodyPlans .itemPlan.active {
  border: 2px solid var(--colorDefault);
  box-shadow: 0px 0px 20px -2px var(--colorDefault);

}
#bodyPlans .itemPlan li i {
  font-size: 20px;
  color: var(--colorDefault);
  margin-right: 5px;
}
#bodyPlans .itemPlan li b {
  color: var(--colorDefault);
}
#bodyPlans .itemPlan .priceMouth {
  /*padding: 0px 0px 10px 0px !important;*/
}
#bodyPlans .itemPlan .priceMouth .big {
  font-size: 40px;
  color: var(--colorDefault);
}
#bodyPlans .itemPlan .priceMouth .oldPrice {
  font-size: 25px;
  color: #929292;
  text-decoration: line-through;
  font-weight: bolder;
}
#bodyPlans .itemPlan .obs {
  font-size: 12px; 
  font-style: italic;
  margin: 15px, 0;
}
#bodyPlans .imgDiscount {
  width: 90px;
  position: absolute;
  right: 0;
  margin-top: -20px;
}

#bodyPlans .divButtonSelect {
  display: inline-flex;
  width: 100%;
  background: #fff;
  /*border: 1px solid #ced4da;*/
  border-radius: 30px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 10px -1px #dbdbdb;
  --webkit-box-shadow: 0px 0px 10px -1px #dbdbdb;
}
#bodyPlans .divButtonSelect .itemButtonSelect {
  width: 50%;
  padding: 10px 15px;
  border-radius: 30px;
  cursor: pointer;
}
#bodyPlans .divButtonSelect .itemButtonSelect.active {
  background: var(--colorDefault);
  color: #fff;
}

#bodyWarranty img {
  margin: 15px;
}

.lineDivider {
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -50px;
}

/* MAILCHIMP */
#formMailchimp input{
  font-family: 'Font-Regular';
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 1.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-bottom: 15px;
}
#formMailchimp button {
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
  margin-bottom: 15px;
}
#formMailchimp .msg-alert {
  text-align: center;
}

.divBtnWhatsApp {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 15px;
}

.btnWhatsApp {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 0;
  background: #4ae666;
  box-shadow: 0px 0px 8px -1px #878787;
  --webkit-box-shadow: 0px 0px 8px -1px #878787;
}

.btnWhatsApp:hover {
  color: #2bcc46;
}

/* Mobile Layout */
@media only screen and (max-width: 767px) {
  #homeIndex .bodyIndex {
    padding: 20px 0;
    height: auto;
  }
  #homeIndex .logo img{
    max-width: 80px;
  }
  #homeIndex .logo h1{
    font-size: 40px;
  }
  .itensHome {
    height: auto;
  }
  .lineDivider {
    width: 100%;
    height: 200px;
    background-image: none !important;
  }
  /*#homeIndex a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
  }*/
}